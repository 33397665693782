import React from "react";
import { Box, Card, CardContent, CardMedia, Grid, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import grey from "@material-ui/core/colors/grey";
import CardActionArea from "@material-ui/core/CardActionArea";
import Dialog from "@material-ui/core/Dialog";
import NewsContent from "./NewsContent.jsx";
import PrimaryDarkTypography from "../../../SharedComponents/PrimaryDarkTypography.jsx";
import { useNews } from "../../../Providers/Data/NewsProvider.jsx";
import useCompanyTitle from "../../../hooks/useCompanyTitle.js";
import useExtractRouteParamInt from "../../../hooks/useExtractRouteParamInt.js";
import { sanitizedData } from "../../../Utils/misc.js";

const useStyles = makeStyles((theme) => ({
  newsWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  newsItem: {
    width: "100%",
    padding: "0 20px",
    margin: "35px 0",
    willChange: "transform",
    transitionProperty: "width",
    transitionDuration: theme.transitions.duration.complex,
  },
  cardMedia: {
    height: 200,
  },
  cardContent: {
    flexGrow: 1,
    backgroundColor: grey[100],
    height: 105,
  },
  cardContentBox: {
    height: 72,
    overflow: "hidden",
  },
  cardText: {
    height: 35,
    overflow: "hidden",
  },
  newsContainer: {
    maxHeight: 600,
    [theme.breakpoints.up("md")]: {
      maxHeight: "100%",
    },
  },
  itemCard: {
    cursor: "pointer",
  },
}));

/** */
const News = () => {
  const classes = useStyles();
  const history = useHistory();
  const { news } = useNews();
  const newsId = useExtractRouteParamInt("/news/:newsId", "newsId");

  useCompanyTitle("title.news");

  /** @param {Number} id */
  const onCardClick = (id) => () => {
    if (id !== newsId) {
      history.push(`/news/${id}`);
    } else {
      history.push("/news");
    }
  };

  if (!news) return null;

  return (
    <Box className={classes.newsWrapper}>
      <Box className={classes.newsItem}>
        <Grid
          className={classes.newsContainer}
          style={{ justifyContent: news.length > 1 ? undefined : "center" }}
          container
          spacing={2}
        >
          {news.map((n) => (
            <Grid className={classes.itemCard} item key={n.id} sm={6} md={4} lg={3}>
              <Card elevation={0} square>
                <CardActionArea onClick={onCardClick(n.id)}>
                  <CardMedia className={classes.cardMedia} image={n.image.url} />
                  <CardContent className={classes.cardContent}>
                    <Box className={classes.cardContentBox}>
                      <PrimaryDarkTypography variant="h6" gutterBottom>
                        {n.name}
                      </PrimaryDarkTypography>
                      {/*<PrimaryDarkTypography variant="overline" gutterBottom>*/}
                      {/*  12/03/2021*/}
                      {/*</PrimaryDarkTypography>*/}
                      <Typography className={classes.cardText} variant="body2">
                        <span dangerouslySetInnerHTML={sanitizedData(n.content)} />
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Dialog open={!!newsId} onClose={() => history.push("/news")} scroll="body" fullWidth maxWidth="sm">
        <NewsContent />
      </Dialog>
    </Box>
  );
};

export default News;
