import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTranslate } from "react-polyglot";
import { useBaseData } from "../Providers/Data/BaseDataProvider.jsx";
import { useAuth } from "../Providers/Auth/AuthProvider.jsx";
import FlexboxVertical from "./FlexboxVertical.jsx";
import iconSad from "../assets/iconSad.svg";

const useStyles = makeStyles(() => ({
  dialogRoot: {
    paddingTop: 0,
    padding: 10,
    "&:first-child": {
      paddingTop: 0,
    },
  },
  sadIcon: {
    width: "70px",
    height: "70px",
    marginTop: "20px",
  },
  textBox: {
    width: "90%",
    margin: "20px auto 0 auto",
  },
  companyDataBox: {
    margin: "20px auto 20px auto",
    textAlign: "center",
  },
  companyData: {
    fontWeight: 600,
  },
  logoutButton: {
    width: "22%",
  },
}));

/** */
const LockDialog = () => {
  const classes = useStyles();
  const { company } = useBaseData();
  const { company2, signOut } = useAuth();
  const t = useTranslate();

  const [isTimeToBlock, setIsTimeToBlock] = useState(false);
  const [companyLock, setCompanyLock] = useState(/** @type {Company} */ null);

  useEffect(() => {
    const company_ = company2 || company;
    setCompanyLock(company_);
    if (company_) {
      setIsTimeToBlock(company_.isTimeToBlock);
    }
  }, [company, company2]);

  if (!companyLock) return null;
  return (
    <Dialog fullWidth maxWidth="md" open={isTimeToBlock} disableBackdropClick disableEscapeKeyDown>
      <DialogContent className={classes.dialogRoot}>
        <FlexboxVertical data-component="PayWebview" alignItems="center">
          <img className={classes.sadIcon} src={iconSad} alt="" />
          <Box className={classes.textBox}>
            <Typography variant="h6">{t("lockText.p1")}</Typography>
            <Typography variant="h6">{t("lockText.p2")}</Typography>
          </Box>
          <Box className={classes.companyDataBox}>
            <Typography className={classes.companyData} variant="h5">
              {companyLock.name}
            </Typography>
            {companyLock.phone && (
              <Typography className={classes.companyData} variant="h6">
                {companyLock.phone}
              </Typography>
            )}
            <Typography className={classes.companyData} variant="h6">
              {companyLock.email}
            </Typography>
          </Box>
          <Button className={classes.logoutButton} variant="contained" color="primary" onClick={() => signOut()}>
            {t("logout")}
          </Button>
        </FlexboxVertical>
      </DialogContent>
    </Dialog>
  );
};

export default LockDialog;
